/* eslint-disable import/prefer-default-export */

const DEFAULT_COMPETENCY = {
  id: null,
  companyId: null,
  name: null,
  description: null,
};

const DEFAULT_COMPETENCY_TABLE_HEADER = [{
  text: 'Название',
  align: 'left',
  sortable: true,
  value: 'name',
},
{
  text: 'Компания',
  align: 'left',
  sortable: true,
  value: 'companyId',
},
{
  text: '',
  align: 'right',
  sortable: false,
  value: 'actions',
}];

const V2_COMPETENCY_TABLE_HEADER = [
  DEFAULT_COMPETENCY_TABLE_HEADER[0],
  DEFAULT_COMPETENCY_TABLE_HEADER[2],
];

export { DEFAULT_COMPETENCY, DEFAULT_COMPETENCY_TABLE_HEADER, V2_COMPETENCY_TABLE_HEADER };
