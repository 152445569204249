<template>
  <TTView>
    <VRow>
      <VCol>
        <CompetencyForm
          :entity="competency"
          :companies="companies"
          @update:name="competency.name = $event"
          @update:description="competency.description = $event"
          @update:companyId="competency.companyId = $event"
          @cancel="handleCancel"
          @submit="handleSubmit"
        />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import { DEFAULT_COMPETENCY } from '../../components/competency-matrix/competencies/common';
import CompetencyForm from '../../components/competency-matrix/competencies/CompetencyForm.vue';

export default {
  name: 'CompetencyCreate',

  components: {
    CompetencyForm,
  },

  data() {
    return {
      companies: [],
      loading: false,
      competency: { ...DEFAULT_COMPETENCY },
    };
  },

  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        const [companiesResponse] = await Promise.all([
          this.$di.api.Account.indexCompany(),
        ]);

        this.companies = companiesResponse.companies || [];
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        // no-finally
      }
    },

    async handleSubmit() {
      try {
        this.loading = true;
        const { competency } = this;
        await this.$di.api.CompetencyMatrix.CompetenciesCreate({ competency });
        this.resetForm();
        this.$di.notify.snackSuccess('Компетенция успешно создана');
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    handleCancel() {
      this.$router.go(-1);
    },

    resetForm() {
      this.competency = { ...DEFAULT_COMPETENCY };
    },
  },
};
</script>
